<template>
  <svg
    viewBox="0 0 24 24">
    <path
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24
      0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12.025,14.9765625 C11.5770833,14.9765625
      11.2359375,15.0885417 11.0015625,15.3125 C10.7671875,15.5364583 10.65,15.8671875
      10.65,16.3046875 C10.65,16.7265625 10.7697917,17.0533854 11.009375,17.2851562
      C11.2489583,17.5169271 11.5875,17.6328125 12.025,17.6328125 C12.4520833,17.6328125
      12.7854167,17.5143229 13.025,17.2773438 C13.2645833,17.0403646 13.384375,16.7161458
      13.384375,16.3046875 C13.384375,15.8776042 13.2658854,15.5494792 13.0289063,15.3203125
      C12.7919271,15.0911458 12.4572917,14.9765625 12.025,14.9765625 Z M13.384375,6 L10.68125,6
      L11.0796875,13.6328125 L12.9859375,13.6328125 L13.384375,6 Z">
    </path>
  </svg>
</template>
